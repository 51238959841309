<script setup lang="ts">
const isLoading = ref(true);
const user = ref(null);

const nuxtApp = useNuxtApp();

getCurrentUser().then(async (u) => {
  console.log(u);
  isLoading.value = false;
  user.value = u;
  console.log("User is, ", u);
  if (!u) {
    console.log("Lets redirect");
    console.log(nuxtApp.$localePath("/login"));
    await navigateTo(nuxtApp.$localePath("/login"));
  }
});
</script>

<template>
  <div v-if="!isLoading && !!user">
    <slot></slot>
  </div>
  <div v-else class="m-auto w-full h-80 flex">
    <font-awesome icon="spinner" size="2x" class="m-auto" spin />
  </div>
</template>

<style scoped>
.info-box {
  line-height: 24px;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  border: 2px solid #11102b;
  .left {
    font-size: 12px;
    width: 50px;
    background-color: #11102b;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
